// eslint-disable-next-line import/no-unresolved
import { Component } from 'preact';
import PropTypes from 'prop-types';

class ButtonLoadMore extends Component {
  render() {
    const { loading } = this.props;

    let contentButton;

    if (loading) {
      contentButton = (
        <div>
          <div className="ml-2 spinner-border" role="status">
            <span className="sr-only">Carregando...</span>
          </div>
          &nbsp; Carregando...
        </div>
      );
    } else {
      contentButton = <span>QUERO VER OUTROS MODELOS </span>;
    }

    return (
      <div className="container text-center">
        <button
          type="button"
          className="list-used-models__btn-load-more btn button button--large button--primary-line button--icon-right"
          onClick={this.props.handleLoadMore}
        >
          {contentButton}
        </button>
      </div>
    );
  }
}

ButtonLoadMore.defaultProps = {
  loading: false,
};

ButtonLoadMore.propTypes = {
  handleLoadMore: PropTypes.func,
  loading: PropTypes.bool,
};

export default ButtonLoadMore;
