/* eslint-disable import/no-unresolved */
import { Component } from 'preact';
import PropTypes from 'prop-types';
import classNames from 'classnames';

class Item extends Component {
  render() {
    const {
      badge,
      item_image,
      subtitle,
      model,
      year,
      slug,
      brand,
      price,
      seals,
      kind,
      km,
      fuel_text,
      exchange,
      isOnFirstPage,
      old_price,
    } = this.props;

    return (
      <div className="card card--used-model">
        <div className="card__header">
          <div className="card__badges">
            <div className="card__badge-item badge badge--pill badge--card-used-model">
              {badge}
            </div>
            {seals.slice(0, 3).map(seal => (
              <div className="card__badge-item badge badge--used-model-seal-list badge--pill">
                {seal}
              </div>
            ))}
          </div>

          <div
            className="card__image card__image--extra-large"
            data-model={model}
            data-brand={brand}
            data-year={year}
            data-slug={slug}
          >
            <a
              href={`${this.props.used_models_link}/${this.props.slug}`}
              alt={`Link para ${this.props.brand} ${this.props.model}`}
            >
              {item_image.middle_image && (
                <img
                  className="card__image-value"
                  src={item_image.middle_image}
                  alt={`Imagem de ${this.props.brand} ${this.props.model}`}
                  width="232"
                  height="175"
                  loading={isOnFirstPage ? 'auto' : 'lazy'}
                  // eslint-disable-next-line react/no-unknown-property
                  fetchpriority={isOnFirstPage ? 'high' : 'low'}
                />
              )}
              {!item_image.middle_image && (
                <div
                  className={classNames({
                    'card__image-value': true,
                    'default-bg__motorcycle_middle-default': kind === 'Moto',
                    'default-bg__model-middle-default': kind !== 'Moto',
                  })}
                />
              )}
            </a>
          </div>
        </div>

        <div className="card__content">
          <div data-match-height="list-used-models-card-content">
            <div className="card__title text-left text-ellipsis">{model}</div>
            <div className="card__subtitle text-left">{subtitle}</div>
          </div>
          <hr className="card__separator" />
          <ul
            className="card__list"
            data-match-height="list-used-models-card-list"
          >
            {exchange ? (
              <li className="card__list-item">Câmbio: {exchange}</li>
            ) : null}
            {km ? (
              <li className="card__list-item">
                Quilometragem: {km.toLocaleString('de-DE')} KM
              </li>
            ) : null}
            {fuel_text ? (
              <li className="card__list-item">Combustível: {fuel_text}</li>
            ) : null}
          </ul>
          <hr className="card__separator" />
        </div>

        <div className="card__footer">
          <div
            data-match-height="list-used-models-card-triggers"
            className="card__triggers"
          >
            {old_price ? (
              <div className="card__trigger">
                De{' '}
                {old_price.toLocaleString('pt-br', {
                  style: 'currency',
                  currency: 'BRL',
                })}
              </div>
            ) : null}
            <div className="card__title">Por apenas</div>
            {price && (
              <div className="card__trigger-value">
                {price.toLocaleString('pt-br', {
                  style: 'currency',
                  currency: 'BRL',
                })}
              </div>
            )}
            <a
              href={`${this.props.used_models_link}/${this.props.slug}`}
              className="btn button button--block button--large button--dark card__cta card__cta-used-model"
              data-model={model}
              data-brand={brand}
              data-year={year}
              data-slug={slug}
            >
              Estou interessado
            </a>
          </div>
        </div>
      </div>
    );
  }
}

Item.defaultProps = {
  item_image: {},
  seals: [],
  isOnFirstPage: false,
};

Item.propTypes = {
  used_models_link: PropTypes.string,
  item_image: PropTypes.arrayOf(PropTypes.string),
  seals: PropTypes.arrayOf(PropTypes.string),
  isOnFirstPage: PropTypes.bool,
  badge: PropTypes.string,
  subtitle: PropTypes.string,
  model: PropTypes.string,
  year: PropTypes.number,
  slug: PropTypes.string,
  brand: PropTypes.string,
  price: PropTypes.number,
  old_price: PropTypes.number,
  kind: PropTypes.string,
  km: PropTypes.number,
  fuel_text: PropTypes.string,
  exchange: PropTypes.string,
};

export default Item;
