function ItemPlaceholder() {
  return (
    <div className="ph-item" style={{ width: '100%' }}>
      <div className="ph-col-12">
        <div className="ph-picture" />
        <div className="ph-row">
          <div className="ph-col-2 empty" />
          <div className="ph-col-8 big" />
          <div className="ph-col-2 empty" />
        </div>
        <div className="ph-row">
          <div className="ph-col-8" />
          <div className="ph-col-8" />
          <div className="ph-col-8" />
        </div>
        <div className="ph-row">
          <div className="ph-col-2 empty" />
          <div className="ph-col-8 big" />
          <div className="ph-col-2 empty" />
        </div>
      </div>
    </div>
  );
}

export default ItemPlaceholder;
