// eslint-disable-next-line no-unused-vars
// eslint-disable-next-line import/no-unresolved
import { Component } from 'react';
import PropTypes from 'prop-types';
import { autoServiceApiV2 } from '../../../services/autoServiceApiV2';
import PlaceholderCard from '../../Cards/Placeholder';

export default class UsedVehiclesFeaturedCarousel extends Component {
  constructor(props) {
    super(props);

    this.state = {
      usedVehiclesList: [],
      isFetching: true,
    };

    this.carousel = null;

    this.initCarousel = this.initCarousel.bind(this);
    this.fetchUsedVehiclesFeatured = this.fetchUsedVehiclesFeatured.bind(this);
  }

  componentDidMount() {
    this.initCarousel();
    this.fetchUsedVehiclesFeatured();
  }

  componentDidUpdate() {
    this.initCarousel();
  }

  async fetchUsedVehiclesFeatured() {
    let usedVehiclesListResult = [];

    try {
      const requestParams = [{ featured: true }, { sort: '-pictures_count' }];
      const result = await autoServiceApiV2.getUsedModels(requestParams);
      const usedVehiclesFeaturedList = result
        ? result.entries
        : this.state.newVehicles;

      usedVehiclesListResult = usedVehiclesFeaturedList;
    } catch (error) {
      console.error(error);
    } finally {
      this.setState(oldState => ({
        ...oldState,
        usedVehiclesList: usedVehiclesListResult,
        isFetching: false,
      }));
    }
  }

  initCarousel() {
    if (!this.elCarousel) return;

    const { isFetching, usedVehiclesList } = this.state;
    if (!isFetching && usedVehiclesList.length <= 0) return;

    if (this.carousel) this.carousel.destroy();

    const carousel = new window.Carousel(this.elCarousel, {
      slidesPerView: 1.12,
      speed: 500,
      navigation: {
        nextEl: '.carousel-used-models-featured__carousel-next',
        prevEl: '.carousel-used-models-featured__carousel-prev',
      },
      spaceBetween: 30,
      breakpoints: {
        560: {
          slidesPerView: 2.5,
        },
        600: {
          slidesPerView: 2.7,
        },
        930: {
          slidesPerView: 4,
        },
        1180: {
          slidesPerView: 4,
        },
      },
      on: {
        init() {
          window.matchHeight.match(
            '.carousel-used-models-featured__carousel-wrapper',
          );
        },
      },
    });

    this.carousel = carousel;
  }

  formatPrice(value) {
    if (typeof value !== 'number') return null;
    return `R$ ${value.toLocaleString('pt-br', { minimumFractionDigits: 2 })}`;
  }

  render() {
    const { usedVehiclesList, isFetching } = this.state;
    const { moduleLink } = this.props;

    return (
      <div className="used-vehicles-featured-component">
        <h2 className="used-vehicles-featured-component__title">
          Ofertas em destaque
        </h2>

        <div
          className="carousel-used-models-featured__carousel carousel swiper-container"
          ref={node => {
            this.elCarousel = node;
          }}
        >
          <div className="carousel-used-models-featured__carousel-wrapper swiper-wrapper">
            {isFetching && (
              <ul className="used-models-featured__carousel-items-wrapper">
                {Array.from({ length: 4 }).map(item => (
                  <li
                    className="used-models-featured__carousel-placeholder-item carousel__item"
                    key={item}
                  >
                    <PlaceholderCard long />
                  </li>
                ))}
              </ul>
            )}
            {!isFetching &&
              usedVehiclesList.length > 0 &&
              usedVehiclesList.map(usedModel => (
                <div
                  className="carousel-used-models-featured__item carousel__item swiper-slide"
                  key={usedModel.slug}
                >
                  <div className="card card--used-model">
                    <div className="card__header">
                      <div className="card__badges">
                        {usedModel.badge ? (
                          <div className="card__badge-item badge badge--pill badge--card-used-model">
                            {usedModel.badge}
                          </div>
                        ) : null}

                        {usedModel.seals.length
                          ? usedModel.seals.slice(0, 3).map(seal => (
                              <div
                                key={seal}
                                className="card__badge-item badge badge--used-model-seal-list badge--used-model-seal-list badge--pill"
                              >
                                {seal}
                              </div>
                            ))
                          : null}
                      </div>

                      <a href={`${moduleLink}/${usedModel.slug}`}>
                        <div className="card__image card__image--extra-large">
                          {usedModel.item_image.middle_image ? (
                            <img
                              className="card__image-value card__image-value--fit"
                              src={usedModel.item_image.middle_image}
                              alt={usedModel.model + usedModel.subtitle}
                            />
                          ) : (
                            <div className="card__image-value  default-bg__model-middle-default" />
                          )}
                        </div>
                      </a>
                    </div>

                    <div
                      data-match-height="card-vehicle-title"
                      className="card__content card__content--text-small"
                    >
                      <div className="card__title">{usedModel.model}</div>
                      <div className="card__subtitle">{usedModel.subtitle}</div>
                      <hr className="card__separator" />

                      <ul
                        className="card__list"
                        data-match-height="list-used-models-card-list"
                      >
                        {usedModel.exchange ? (
                          <li className="card__list-item">
                            Câmbio: {usedModel.exchange}
                          </li>
                        ) : null}
                        {usedModel.km ? (
                          <li className="card__list-item">
                            Quilometragem: {usedModel.km}
                          </li>
                        ) : null}
                        {usedModel.fuel_text ? (
                          <li className="card__list-item">
                            Combustível: {usedModel.fuel_text}
                          </li>
                        ) : null}
                      </ul>
                    </div>

                    <div className="card__footer">
                      <div
                        data-match-height="carousel-used-models-featured-card-triggers"
                        className="card__triggers"
                      >
                        {usedModel.old_price ? (
                          <div className="card__trigger">
                            De <s>{this.formatPrice(usedModel.old_price)}</s>
                          </div>
                        ) : null}

                        {usedModel.price ? (
                          <div>
                            <div className="card__title">Por apenas</div>
                            <div className="card__trigger-value">
                              {this.formatPrice(usedModel.price)}
                            </div>
                          </div>
                        ) : null}
                      </div>
                    </div>

                    <a
                      href={`${moduleLink}/${usedModel.slug}`}
                      className="btn button button--block button--large
                      button--dark card__cta card__cta-used-model"
                    >
                      Estou interessado
                    </a>
                  </div>
                </div>
              ))}
            {!isFetching && usedVehiclesList.length <= 0 && (
              <p className="used-models-featured__no-vehicles-message">
                Nenhum veículo seminovo em destaque encontrado.
              </p>
            )}
          </div>
        </div>
      </div>
    );
  }
}

UsedVehiclesFeaturedCarousel.defaultProps = {
  moduleLink: '/seminovos',
};

UsedVehiclesFeaturedCarousel.propTypes = {
  moduleLink: PropTypes.string,
};
