import { Component } from 'react';
import PropTypes from 'prop-types';
import TimeMissingCard from '../../TimeMissingCard';
import FeaturesDisplay from '../DDM/FeaturesDisplay';

export default class FcaCard extends Component {
  getFormatExpiredAt(date) {
    if (!window.railsDateToChronometer) return;

    // eslint-disable-next-line consistent-return
    return window.railsDateToChronometer(date);
  }

  compareTitles(title, subtitle) {
    const fcaApiBrand = document.querySelector("meta[name='fca_api_brand']");
    const brand = fcaApiBrand ? fcaApiBrand.content : '';

    return title === subtitle ? brand : subtitle;
  }

  render() {
    const {
      title,
      subtitle,
      image,
      link,
      sealList,
      offer,
      showBadge,
      badge,
      showExpiredAt,
      expiredAt,
      onClickCard
    } = this.props;

    const {
      secondary_call,
      main_call,
      featured,
      description,
      features,
      has_features_list,
    } = offer;

    return (
      <div className="card offers-fca-card">
        {showExpiredAt && !showBadge && (
          <div className="offers-fca-card__time-missing">
            <TimeMissingCard date={this.getFormatExpiredAt(expiredAt)} />
          </div>
        )}

        <div className="card__header">
          <a className="offers-fca-card__link" href={link} onClick={onClickCard}>
            <img
              className="offers-fca-card__image"
              src={image}
              alt={title}
              width="200"
              height="145"
            />
          </a>
        </div>

        <div className="card__content offers-fca-card__content">
          <div data-match-height="offer-vehicle-card-content">
            <div className="card__title offer-card__title">{title}</div>
            <div className="card__subtitle">
              {this.compareTitles(title, subtitle)}
            </div>
          </div>
          <hr className="card__separator" />
          <div data-match-height="offer-vehicle-card-list">
            <ul className="card__list list offers-fca-card__list">
              {sealList.map(item => (
                <li className="offers-fca-card__list-item">{item}</li>
              ))}
            </ul>
          </div>
          <hr className="card__separator" />
        </div>

        <div className="card__footer">
          <div
            data-match-height="offer-vehicle-footer"
            className="card__box-offer offers-fca-card__prices"
          >
            {secondary_call && (
              <p className="card__trigger-starting-price">{secondary_call}</p>
            )}
            {main_call && (
              <small className="card__title footer-card__title">
                {main_call}
              </small>
            )}
            {featured && <div className="card__trigger-value">{featured}</div>}
            {description && (
              <div className="card__trigger-description">{description}</div>
            )}

            {has_features_list && features && (
              <FeaturesDisplay features={features} />
            )}
          </div>
          <a
            href={link}
            className="btn button button--block button--large button--primary card__cta"
            onClick={onClickCard}
          >
            Estou Interessado
          </a>
        </div>

        {showBadge && (
          <div className="offers-fca-card__badge">
            <small className="offers-fca-card__badge-title">{badge}</small>
          </div>
        )}
      </div>
    );
  }
}

FcaCard.defaultProps = {
  title: '',
  subtitle: '',
  image: '',
  link: '',
  sealList: [],
  offer: {},
  showBadge: false,
  badge: '',
  showExpiredAt: false,
  expiredAt: '',
};

FcaCard.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  image: PropTypes.string,
  link: PropTypes.string,
  sealList: PropTypes.arrayOf(PropTypes.string),
  offer: PropTypes.shape({
    secondary_call: PropTypes.string,
    main_call: PropTypes.string,
    featured: PropTypes.string,
    description: PropTypes.string,
    features: PropTypes.arrayOf(PropTypes.string),
    has_features_list: PropTypes.bool,
  }),
  showBadge: PropTypes.bool,
  badge: PropTypes.string,
  showExpiredAt: PropTypes.bool,
  expiredAt: PropTypes.string,
  onClickCard: PropTypes.func
};
