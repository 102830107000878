// eslint-disable-next-line import/no-unresolved
import { Component } from 'preact';
import PropTypes from 'prop-types';
import {
  nextPage,
  resetList,
  setList,
  setComponentsExhibition,
  setSort,
  incrementList,
} from '../../../../stores/Filters/UsedVehicles/FilterV2';

import List from './List';
import ButtonLoadMore from './ButtonLoadMore';

class ListApp extends Component {
  constructor(props) {
    super(props);

    this.state = {
      vehiclesList: [],
      hasMorePages: false,
      loading: true,
      loadingMore: false,
      showList: true,
    };

    resetList([]);
    if (this.props.channelUsedVehiclesSort)
      setSort(this.props.channelUsedVehiclesSort);

    if (!this.state.loading && this.state.vehiclesList.length === 0) {
      setComponentsExhibition({
        formNoResults: false,
      });
    }

    this.handleLoadMore = this.handleLoadMore.bind(this);
  }

  componentDidMount() {
    this.watchStore();
    setList();
  }

  handleLoadMore() {
    this.setState(oldState => ({
      ...oldState,
      loadingMore: true,
    }));

    nextPage();
    incrementList();
  }

  watchStore() {
    window.store.FilterV2.watch(state => {
      this.setState({
        vehiclesList: state.list,
        loading: state.loading,
        loadingMore: state.loadingMore,
        hasMorePages: state.total / state.per_page > state.page,
        showList: !state.componentsExhibition.formNoResults,
      });
    });
  }

  render() {
    const { vehiclesList, loading, loadingMore, hasMorePages, showList } =
      this.state;
    const { moduleLink } = this.props;

    if (!showList) return null;

    return (
      <div className="container">
        <List
          items={vehiclesList}
          loading={loading}
          loadingMore={loadingMore}
          showLoadMore={hasMorePages}
          used_models_link={this.props.used_models_link}
          moduleLink={moduleLink}
        />
        {hasMorePages && (
          <ButtonLoadMore
            loadingMore={loadingMore}
            handleLoadMore={this.handleLoadMore}
          />
        )}
      </div>
    );
  }
}

ListApp.propTypes = {
  used_models_link: PropTypes.string,
  channelUsedVehiclesSort: PropTypes.string,
  moduleLink: PropTypes.string,
};

export default ListApp;
