// eslint-disable-next-line import/no-unresolved
import { h } from 'preact';
// eslint-disable-next-line react/no-deprecated, import/no-unresolved
import { render } from 'react';
import UsedVehiclesCard from '../auto/components/Cards/UsedVehiclesCitroen';
import FcaCard from '../auto/components/Cards/Fca';
import OfferDDMListApp from '../auto/components/Listings/OffersDDMV2/ListApp';
import OfferDDMFilter from '../auto/components/Listings/OffersDDMV2/Filter/SimpleFilterV2';
import OfferDDMResultSearchSimple from '../auto/components/Listings/OffersDDM/Filter/Result/ResultSearchSimple';
import OfferDDMConversionFormNoResult from '../auto/components/Listings/OffersDDM/Filter/Result/ConversionFormNoResult';
import VersionComparison from '../auto/components/VersionComparison/VersionComparison';
import WhatsappChatConversionForm from '../auto/components/Whatsapp/ChatConversionForm';
import FeaturesDisplay from '../auto/components/Cards/DDM/FeaturesDisplay';
import ListUsedVehiclesApp from '../auto/components/Listings/UsedVehicles/JeepV2';
import UsedVehiclesFilter from '../auto/components/Filters/UsedVehiclesFilter/UsedVehiclesFilterApp';
import UsedVehiclesFeaturedCarousel from '../auto/components/Carousels/JeepV2/UsedVehiclesFeatured';

import SimpleFilterButtonsApp from '../auto/components/SearchFilter/SimpleFilterButtonsApp';
import SimpleFilterNewVehicleV2 from '../auto/components/NewVehicleV2/SimpleFilterV2';
import ListNewVehicleV2 from '../auto/components/NewVehicleV2/ListAppV2';
import ResultSearchSimpleApp from '../auto/components/SearchFilter/ResultSearchSimpleApp';
import ConversionFormNoResultApp from '../auto/components/Forms/ConversionFormAppV2';

if (process.env.NODE_ENV === 'development') {
  // eslint-disable-next-line global-require, import/no-unresolved
  require('preact/debug');
}

(function () {
  window.h = h;
  window.render = render;
  /**
   * Para ser usado quando se deseja sobrescrever conteúdo do container de rendeziração.
   * Seu uso é semelhante a função render
   */
  window.hydrate = (vnode, parent) =>
    render(vnode, parent, parent.firstElementChild);

  // Adicione aqui chaves:valor com os componentes que serão atribuídos ao escopo
  // global. Não passe eles pelo hyperscript (h) aqui pois a página que os usa
  // é quem deve compilá-lo com os pros.
  const components = {
    UsedVehiclesCard,
    FcaCard,
    OfferDDMListApp,
    OfferDDMFilter,
    OfferDDMResultSearchSimple,
    OfferDDMConversionFormNoResult,
    WhatsappChatConversionForm,
    VersionComparison,
    FeaturesDisplay,
    ListUsedVehiclesApp,
    UsedVehiclesFilter,
    UsedVehiclesFeaturedCarousel,
    SimpleFilterButtonsApp,
    SimpleFilterNewVehicleV2,
    ListNewVehicleV2,
    ResultSearchSimpleApp,
    ConversionFormNoResultApp,
  };

  Object.keys(components).forEach(c => {
    window[c] = components[c];
  });
})();
