// eslint-disable-next-line no-unused-vars
import { h, Component } from 'preact';
import PropTypes from 'prop-types';
import { mask } from '%/utils/formUtils/maks/mask';
import Input from '../../Forms/FormComponents/Fields/Input';
import ControlledSelect from '../../Forms/FormComponents/Fields/ControlledSelect';
import AdvancedFilterHeader from './AdvancedFilter/AdvancedFilterHeader';
import AdvancedFilterFooter from './AdvancedFilter/AdvancedFilterFooter';
import AdvancedFilterButtonSubmit from './AdvancedFilter/AdvancedFilterButtonSubmit';
import {
  setComponentsExhibition,
  setFiltersList,
  setKeyword,
  setList,
  setPage,
} from '../../../stores/Filters/UsedVehicles/FilterV2';
import { mapFiltersV2 } from '../../../utils/filters/usedVehicles/mapFiltersV2';
import { mapFiltersList } from '../../../utils/filters/usedVehicles/mapFiltersList';
import { setOptions } from '../../../stores/Filters/UsedVehicles/FilterV2Options';
import { mapFiltersListToComponentState } from '../../../utils/filters/usedVehicles/mapFiltersListToComponentState';
import { applyURLSearchParams } from '../../../utils/filters/usedVehicles/applyURLSearchParams';
import {
  desktopFieldsPlaceholder,
  mobileFieldsPlaceholder,
} from '../../../utils/filters/usedVehicles/fieldsPlaceholder';

class AdvancedFilter extends Component {
  constructor(props) {
    super(props);

    this.state = {
      filtersList: {},
      filterOptions: {},
    };

    this.handleFieldChange = this.handleFieldChange.bind(this);
    this.handleBrandChange = this.handleBrandChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.configureMasks = this.configureMasks.bind(this);
    this.modelsFilterOptions = this.modelsFilterOptions.bind(this);
    this.recoverFilterState = this.recoverFilterState.bind(this);
    this.watchFilterV2OptionsStore = this.watchFilterV2OptionsStore.bind(this);
    this.fieldsPlaceholder = this.fieldsPlaceholder.bind(this);
  }

  componentDidMount() {
    const { withNewVehicle } = this.props;

    setOptions({ withNewVehicle });

    this.watchFilterV2OptionsStore();
    this.recoverFilterState();
    this.configureMasks();
  }

  handleFieldChange(event, cb) {
    const { name, value } = event.target;

    this.setState(oldState => ({
      ...oldState,
      filtersList: {
        ...oldState.filtersList,
        [name]: value,
      },
    }));

    if (cb) cb(name, value);
  }

  handleBrandChange() {
    this.setState(oldState => {
      const filtersListState = oldState.filtersList;
      delete filtersListState.model;

      return {
        ...oldState,
        filtersList: {
          ...filtersListState,
        },
      };
    });
  }

  handleSubmit() {
    const { filtersList } = this.state;
    const filtersListWithSanitizedPrices = this.convertPrices(filtersList);

    const mappedFiltersList = mapFiltersList(
      filtersListWithSanitizedPrices,
      mapFiltersV2,
    );
    setFiltersList(mappedFiltersList);

    setKeyword(filtersListWithSanitizedPrices.keyword);
    setPage(1);
    setList();
    setComponentsExhibition({
      results: true,
      advancedFilter: false,
    });

    if (this.props.shouldSetURLSearchParams)
      applyURLSearchParams(filtersListWithSanitizedPrices);
  }

  convertPrices(data) {
    return Object.fromEntries(
      Object.entries(data).map(([key, value]) => {
        const lowerCaseKey = key.toLowerCase();

        if (lowerCaseKey.includes('price') && typeof value === 'string') {
          return [key, parseInt(value.replace(/\./g, ''), 10)];
        }

        return [key, value];
      }),
    );
  }

  recoverFilterState() {
    const { filtersList } = window.store.FilterV2.getState();

    this.setState(oldState => ({
      ...oldState,
      filtersList: mapFiltersListToComponentState(filtersList),
    }));
  }

  watchFilterV2OptionsStore() {
    window.store.FilterV2Options.watch(state => {
      this.setState(oldState => ({
        ...oldState,
        filterOptions: state.options,
      }));
    });
  }

  configureMasks() {
    if (this.priceMinInput) {
      mask(this.priceMinInput, { mask: 'price' }, priceMaskvalue =>
        this.setState(oldState => ({
          ...oldState,
          filtersList: {
            ...oldState.filtersList,
            [this.priceMinInput.name]: priceMaskvalue,
          },
        })),
      );
    }

    if (this.priceMaxInput) {
      mask(this.priceMaxInput, { mask: 'price' }, priceMaskvalue =>
        this.setState(oldState => ({
          ...oldState,
          filtersList: {
            ...oldState.filtersList,
            [this.priceMaxInput.name]: priceMaskvalue,
          },
        })),
      );
    }
  }

  modelsFilterOptions() {
    const { filterOptions, filtersList } = this.state;
    return filterOptions.models ? filterOptions.models[filtersList.brand] : [];
  }

  fieldsPlaceholder(fieldName) {
    const { isMobile, customFieldsPlaceholder } = this.props;

    if (customFieldsPlaceholder) {
      return customFieldsPlaceholder[fieldName];
    }

    const fieldsPlaceholderMap = isMobile
      ? mobileFieldsPlaceholder
      : desktopFieldsPlaceholder;

    return fieldsPlaceholderMap[fieldName];
  }

  render() {
    const { filtersList, filterOptions } = this.state;
    const { units, showFooter, isMobile } = this.props;

    return (
      <form
        className="advanced-filter"
        onSubmit={event => event.preventDefault()}
      >
        <AdvancedFilterHeader clearFilters={this.recoverFilterState} />
        <div className="advanced-filter__body">
          <div className="advanced-filter__body-row">
            <ControlledSelect
              name="brand"
              placeholder={this.fieldsPlaceholder('brand')}
              options={filterOptions.brands}
              value={filtersList.brand || 'empty'}
              handleChange={event =>
                this.handleFieldChange(event, this.handleBrandChange)
              }
            />
            <ControlledSelect
              name="model"
              placeholder={this.fieldsPlaceholder('model')}
              options={this.modelsFilterOptions()}
              value={filtersList.model || 'empty'}
              handleChange={this.handleFieldChange}
            />
            <Input
              name="keyword"
              value={filtersList.keyword}
              placeholder={this.fieldsPlaceholder('keyword')}
              handleChange={this.handleFieldChange}
              customClasses="advanced-filter__field-large"
            />
          </div>
          <div className="advanced-filter__body-row">
            <Input
              name="min_price"
              placeholder={this.fieldsPlaceholder('min_price')}
              handleChange={this.handleFieldChange}
              setRef={priceMinInput => {
                this.priceMinInput = priceMinInput;
              }}
              value={filtersList.min_price}
            />
            <Input
              name="max_price"
              placeholder={this.fieldsPlaceholder('max_price')}
              handleChange={this.handleFieldChange}
              setRef={priceMaxInput => {
                this.priceMaxInput = priceMaxInput;
              }}
              value={filtersList.max_price}
            />
            <ControlledSelect
              name="min_model_year"
              placeholder={this.fieldsPlaceholder('min_model_year')}
              options={filterOptions.min_model_year}
              handleChange={this.handleFieldChange}
              value={filtersList.min_model_year || 'empty'}
            />
            <ControlledSelect
              name="max_model_year"
              placeholder={this.fieldsPlaceholder('max_model_year')}
              options={filterOptions.max_model_year}
              handleChange={this.handleFieldChange}
              value={filtersList.max_model_year || 'empty'}
            />
          </div>
          <div className="advanced-filter__body-row">
            <ControlledSelect
              name="min_km"
              placeholder={this.fieldsPlaceholder('min_km')}
              options={filterOptions.min_km}
              value={filtersList.min_km || 'empty'}
              handleChange={this.handleFieldChange}
            />
            <ControlledSelect
              name="max_km"
              placeholder={this.fieldsPlaceholder('max_km')}
              options={filterOptions.max_km}
              value={filtersList.max_km || 'empty'}
              handleChange={this.handleFieldChange}
            />

            {units.length > 1 && (
              <ControlledSelect
                name="unit"
                placeholder={this.fieldsPlaceholder('unit')}
                options={units}
                value={filtersList.unit || 'empty'}
                handleChange={this.handleFieldChange}
              />
            )}

            <AdvancedFilterButtonSubmit
              handleSubmit={this.handleSubmit}
              shouldSpan={units.length <= 1}
            />
          </div>
        </div>

        {showFooter && isMobile && (
          <AdvancedFilterFooter
            clearFilters={this.recoverFilterState}
            handleSubmit={this.handleSubmit}
          />
        )}
      </form>
    );
  }
}

AdvancedFilter.defaultProps = {
  shouldSetURLSearchParams: false,
  isMobile: false,
  units: [],
  customFieldsPlaceholder: null,
  showFooter: false,
  withNewVehicle: false,
};

AdvancedFilter.propTypes = {
  shouldSetURLSearchParams: PropTypes.bool,
  isMobile: PropTypes.bool,
  units: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string,
    }),
  ),
  customFieldsPlaceholder: PropTypes.objectOf(PropTypes.string),
  showFooter: PropTypes.bool,
  withNewVehicle: PropTypes.bool,
};

export default AdvancedFilter;
