/* eslint-disable jsx-a11y/anchor-is-valid */
import { Component } from 'react';
import PropTypes from 'prop-types';
import IMask from 'imask';
import handleInputChange from '%/utils/handleInputChange';
import validationFactory from '%/utils/validation';
import upperCaseAndRemoveAccent from '%/utils/upperCaseAndRemoveAccent';
import { phoneMasks, phoneMasksPt } from '%/utils/masks';
import getUTM from '%/utils/utm';
import CustomSelect from './CustomSelect';
import CustomCheck from './CustomCheck';
import SubmitButton from './SubmitButton';
import FormMessageOverlay from './FormMessageOverlay';
import InputCpf from './InputCpf';
import LocationFields from './Inputs/Location';
import { getChannelCountry } from '../utils/internacionalization';
import { setRecaptchaLoadOnFormFocus } from '../utils/reCAPTCHA';

class WhatsAppForm extends Component {
  constructor(props) {
    super(props);

    this.resetState();

    this.clearForm = this.clearForm.bind(this);
    this.handleInputChange = handleInputChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.getFormData = this.getFormData.bind(this);
    this.handleContactOptionsChange =
      this.handleContactOptionsChange.bind(this);
    this.handleStateChange = this.handleStateChange.bind(this);
    this.handleCityChange = this.handleCityChange.bind(this);
  }

  componentDidMount() {
    const masks = {
      BR: phoneMasks,
      PT: phoneMasksPt,
    };

    const phoneMask = IMask(this.phoneInput, {
      mask: masks[this.props.country || getChannelCountry()],
    });
    phoneMask.on('accept', () => this.setState({ phone: phoneMask.value }));

    this.validator = validationFactory(`#${this.props.formId}`);

    setRecaptchaLoadOnFormFocus();
  }

  componentDidUpdate() {
    window.whatsappDidUpdate();
  }

  handleContactOptionsChange(e) {
    this.handleInputChange(e);
    this.state.contactOptions = this.contactOptionsValue() ? 'on' : '';
  }

  handleStateChange(e) {
    const uf = e.target.value;
    this.setState({ uf, subsidiary: '', subsidiaries: [] });
  }

  handleCityChange(e) {
    const city = e.target.value;
    this.setState({ city, subsidiary: '' });

    if (this.props.filterUnitsByLocation && this.state.uf && this.state.city) {
      this.setState({ subsidiaryDisabled: true });

      const apiQuery = [
        { 'q[state_cont]': this.state.uf },
        { 'q[city_cont]': this.state.city },
      ];

      const categoryUpperCase = upperCaseAndRemoveAccent(
        this.props.modelCategory,
      );

      if (categoryUpperCase === 'ONIBUS') {
        apiQuery.push({ 'q[bus_eq]': true });
      } else {
        apiQuery.push({ 'q[truck_eq]': true });
      }

      window.autoServiceApi.getServedRegion(apiQuery).then(res => {
        const subsidiaries = res.entries.map(region => ({
          label: region.display_name,
          value: region.display_name,
          city: region.city,
        }));

        let subsidiariesUniq = this.uniqByKeepFirst(
          subsidiaries,
          sub => sub.label,
        );

        // TODO atualizar filtro do apicasso para remover nomes parciais, ex: SP cidade Arujá que retorna Guarujá.
        subsidiariesUniq = subsidiariesUniq.filter(
          s => s.city.length === this.state.city.length,
        );

        this.setState({
          subsidiaries: subsidiariesUniq,
          subsidiary: '',
          subsidiaryDisabled: false,
        });
      });
    }
  }

  handleSubmit(e) {
    e.preventDefault();

    const invalidInputs = this.validator.validateAll(this.form);
    const formIsValid = invalidInputs.length === 0;

    if (
      this.props.versions &&
      this.props.versions.length > 0 &&
      !this.getActiveVersion()
    ) {
      if (!this.versionValid()) {
        this.setState({ versionValidStatus: false });
        return;
      }
    }

    if (this.props.filterUnitsByLocation && this.props.showLocationFields)
      this.validateSubsidiary();

    if (this.props.showLocationFields) this.validateLocationFields();

    if (!formIsValid) {
      return;
    }

    if (!this.cpfValid()) {
      alert('O campo de CPF é obrigatório');
      return;
    }

    // Se o form for válido, chamamos a prop para gerenciar a submissão do form.
    this.props.handleSubmit(e, this.getFormData()).then(() => {
      this.resetState();
    });
  }

  getActiveVersion() {
    if (!window.store || !window.store.vehicleVersionDetails) return null;
    return window.store.vehicleVersionDetails.getState().activeVersion;
  }

  /**
   * Retorna os dados pertinentes para a conversão.
   * @return {*}
   */
  getFormData() {
    const state = {
      name: this.state.name,
      email: this.state.email,
      phone: this.state.phone,
      model_car: this.state.model_car,
      mailing: this.state.mailing,
      phoning: this.state.phoning,
      whatsapping: this.state.whatsapping,
    };

    if (this.props.showCpf) {
      state.cpf = this.state.cpf;
    }
    if (this.props.shouldShowDataPermissions) {
      state.data_permissions = this.state.dataPermissions;
    }

    if (this.props.showLocationFields) {
      state.uf = this.state.uf;
      state.city = this.state.city;
    }

    if (this.state.subsidiary) {
      state.subsidiary = this.state.subsidiary;
    }

    const props = {
      product: this.props.product,
      bait: this.props.bait,
      channel: this.props.channel,
      category: this.props.category,
      brand: this.props.brand,
      utmz: getUTM(),
      bait_type: 'whatsapp',
    };

    const activeVersion =
      this.props.versions &&
      this.props.versions.length > 0 &&
      this.getActiveVersion();

    if (this.props.cloneId) {
      props.product_id = this.props.cloneId;
    } else if (activeVersion && activeVersion.clone_id) {
      props.product_id = activeVersion.clone_id;
    }

    if (activeVersion && activeVersion.slug) {
      props.version = activeVersion.slug;
    }

    if (!this.props.whatsAppResponder) props.whatsapp_no_responder = true;

    if (this.props.unit) {
      props.unit = this.props.unit;
    } else if (this.props.units && this.props.units.length === 1) {
      props.unit = this.props.units[0].value;
    } else {
      props.unit = this.state.unit;
    }

    const plan = document.getElementsByName('plan')[0];
    const version = document.getElementsByName('version')[0];
    const period = document.getElementsByName('period')[0];
    if (plan || version || period) {
      props.plan = plan.value;
      props.version = version.value;
      props.period = period.value;
    }

    /*
    Para o consórcio, basta enviar o slug da versão que não necessariamente o dealer possui como oferta. Para isto
    usamos o campo 'model_car' que no integrador DCJ Grow (antigo Salesforce) é usado exatamente para este fim.
    */
    if (this.props.category === 'Consortium') {
      const { plans } = window.store.Consortium.getState();

      if (plans.version_id) {
        props.model_car = plans.version_id;
      }
    }

    this.props.customFields.forEach(field => {
      props[field.name] = field.value;
    });

    return { ...state, ...props };
  }

  versionValid() {
    return this.state.model_car;
  }

  validateSubsidiary() {
    const elSubsidiarySelector = document.querySelector(
      '.subsidiary-whatsapp-select .choices__inner',
    );
    elSubsidiarySelector.classList.toggle('is-invalid', !this.state.subsidiary);
  }

  uniqByKeepFirst(a, key) {
    const seen = new Set();
    return a.filter(item => {
      const k = key(item);
      return seen.has(k) ? false : seen.add(k);
    });
  }

  /*
   * Verifica se não há opções de contato selecionadas
   */
  contactOptionsValue() {
    return !!(
      this.state.phoning ||
      this.state.mailing ||
      this.state.whatsapping
    );
  }

  /**
   * Validação extra para o cpf
   */
  cpfValid() {
    return !this.props.showCpf || (this.props.showCpf && this.state.cpf);
  }

  validateLocationFields() {
    if (!this.state.city) {
      document
        .getElementsByName('city')[0]
        .parentElement.classList.add('is-invalid');
      if (!this.state.uf) {
        document
          .getElementsByName('uf')[0]
          .parentElement.classList.add('is-invalid');
      } else {
        document
          .getElementsByName('uf')[0]
          .parentElement.classList.remove('is-invalid');
      }
    }
  }

  resetState() {
    this.setState({
      name: '',
      email: '',
      phone: '',
      unit: '',
      phoning: false,
      mailing: false,
      whatsapping: false,
      contactOptions: '',
      dataPermissions: false,
      cpf: '',
      model_car: '',
      versionValidStatus: true,
      uf: null,
      city: null,
      subsidiaries: [],
      subsidiary: '',
      subsidiaryDisabled: false,
    });
  }

  clearForm(e) {
    e.preventDefault();
    this.resetState();
  }

  render() {
    return (
      <div>
        <form
          id={this.props.formId}
          className="form-modal form-modal--whatsapp"
          onSubmit={this.handleSubmit}
          ref={form => {
            this.form = form;
          }}
        >
          <FormMessageOverlay
            handleClose={this.props.handleCloseOverlay}
            isVisible={this.props.showOverlay}
            type={this.props.errorSendingForm ? 'error' : 'success'}
          />
          <header className="form-modal__header">{this.props.titleForm}</header>
          <div className="form-modal__body">
            <header>
              Preencha o formulário abaixo e converse agora com um consultor:
            </header>
            <fieldset>
              <div className="form-group">
                <input
                  type="text"
                  placeholder="Nome:"
                  name="name"
                  className="form-control"
                  maxLength={80}
                  onChange={this.handleInputChange}
                  value={this.state.name}
                  required
                  data-bouncer-target="#invalid-name"
                />
                <div id="invalid-name" className="invalid-feedback" />
              </div>
              {this.props.showEmail && (
                <div className="form-group">
                  <input
                    type="email"
                    placeholder="E-mail:"
                    name="email"
                    className="form-control"
                    maxLength={80}
                    onChange={this.handleInputChange}
                    value={this.state.email}
                    required
                    data-bouncer-target="#invalid-email"
                  />
                  <div id="invalid-email" className="invalid-feedback" />
                </div>
              )}
              <div className="form-group">
                <input
                  type="phone"
                  placeholder="Telefone/WhatsApp:"
                  name="phone"
                  className="form-control"
                  ref={phoneInput => {
                    this.phoneInput = phoneInput;
                  }}
                  value={this.state.phone}
                  autoComplete="off"
                  required
                  onChange={this.handleInputChange}
                  data-bouncer-target="#invalid-phone"
                />
                <div id="invalid-phone" className="invalid-feedback" />
              </div>

              {this.props.showCpf && (
                <InputCpf
                  handleChange={this.handleInputChange}
                  value={this.state.cpf}
                />
              )}

              {this.props.showLocationFields && (
                <LocationFields
                  handleStateChange={this.handleStateChange}
                  uf={this.state.uf}
                  handleCityChange={this.handleCityChange}
                  city={this.state.city}
                />
              )}

              {this.props.versions &&
                this.props.versions.length > 0 &&
                !this.getActiveVersion() && (
                  <div className="form-group">
                    <CustomSelect
                      handleSelectChange={this.handleInputChange}
                      name="model_car"
                      options={this.props.versions}
                      value={this.state.model_car}
                      shouldSort={false}
                      placeholderValue="Escolha um modelo"
                      searchEnabled
                    />

                    {!this.state.versionValidStatus && (
                      <div
                        id="invalid-version"
                        className="invalid-feedback is-invalid-version"
                      >
                        <div
                          className="error-message"
                          id="bouncer-error_version"
                        >
                          Por favor, selecione esse campo
                        </div>
                      </div>
                    )}
                  </div>
                )}

              {!this.props.unit &&
                this.props.units &&
                this.props.units.length > 1 &&
                this.props.showUnits && (
                  <div>
                    <legend className="control-label">
                      Escolha a unidade:
                    </legend>
                    <div className="form-group unit-select">
                      <CustomSelect
                        handleSelectChange={this.handleInputChange}
                        name="unit"
                        options={this.props.units}
                        value={this.state.unit}
                        shouldSort={false}
                        placeholderValue="Selecione uma unidade"
                        searchEnabled={false}
                      />
                    </div>
                  </div>
                )}

              {this.props.filterUnitsByLocation &&
                this.props.showLocationFields && (
                  <div>
                    <div className="form-group subsidiary-whatsapp-select">
                      <CustomSelect
                        handleSelectChange={this.handleInputChange}
                        name="subsidiary"
                        options={this.state.subsidiaries}
                        value={this.state.subsidiary}
                        shouldSort={false}
                        placeholderValue="Selecione sua concessionária"
                        searchEnabled={false}
                        disable={this.state.subsidiaryDisabled}
                      />
                    </div>
                  </div>
                )}
              {this.props.filterUnitsByLocation &&
                this.props.showLocationFields && (
                  <div className="form-group subsidiary-options">
                    <input
                      type="text"
                      className="form-control d-none"
                      name="subsidiary-options"
                      required
                      placeholder="Opções de Consessionaria"
                      data-bouncer-target="#invalid-subsidiary-options"
                      value={this.state.subsidiary}
                      checked={this.state.subsidiary}
                    />
                    <div
                      id="invalid-subsidiary-options"
                      className="invalid-feedback"
                    />
                  </div>
                )}

              {this.props.showContactOptions && (
                <div>
                  <p className="control-label">Quero receber contato por:</p>
                  <div className="form-check form-check-inline">
                    <CustomCheck
                      checkStyle="checkbox-whatsapp"
                      name="mailing"
                      value="true"
                      type="checkbox"
                      isChecked={this.state.mailing}
                      onChangeCheckable={this.handleContactOptionsChange}
                    >
                      E-mail
                    </CustomCheck>
                  </div>
                  <div className="form-check form-check-inline">
                    <CustomCheck
                      checkStyle="checkbox-whatsapp"
                      name="whatsapping"
                      value="true"
                      type="checkbox"
                      isChecked={this.state.whatsapping}
                      onChangeCheckable={this.handleContactOptionsChange}
                    >
                      Whatsapp
                    </CustomCheck>
                  </div>
                  <div className="form-check form-check-inline">
                    <CustomCheck
                      checkStyle="checkbox-whatsapp"
                      name="phoning"
                      value="true"
                      type="checkbox"
                      isChecked={this.state.phoning}
                      onChangeCheckable={this.handleContactOptionsChange}
                    >
                      Telefone
                    </CustomCheck>
                  </div>
                  <div className="form-group contact-options">
                    <input
                      type="text"
                      className="form-control d-none"
                      name="contact-options"
                      required
                      placeholder="Opções de contato"
                      data-bouncer-target="#invalid-contact-options"
                      value={this.state.contactOptions}
                      checked={this.state.contactOptions}
                    />
                    <div
                      id="invalid-contact-options"
                      className="invalid-feedback"
                    />
                  </div>
                </div>
              )}
            </fieldset>
            {this.props.shouldShowDataPermissions && (
              <fieldset className="mt-0">
                <div className="form-check form-check-inline conversion-form__data-permissions-field dark">
                  <CustomCheck
                    name="dataPermissions"
                    value="true"
                    isChecked={this.state.dataPermissions}
                    onChangeCheckable={this.handleInputChange}
                    type="checkbox"
                    checkStyle="checkbox-whatsapp"
                  >
                    {this.props.dataPermissionsCustomText}
                  </CustomCheck>
                </div>
              </fieldset>
            )}
            <fieldset className="consent-text mt-2">
              Ao informar meus dados, eu concordo com a{' '}
              <a href={this.props.linkPrivacyPolicy}>Política de privacidade</a>
              .
            </fieldset>
          </div>
          <footer>
            <a className="clear-fields" href="#" onClick={this.clearForm}>
              Limpar campos
            </a>
            <SubmitButton
              classes="btn form-modal__submit submit-button button button--whatsapp mb-0"
              handleClick={this.handleSubmit}
              label="Converse agora!"
              isSubmitting={this.props.isSubmittingForm}
            />
          </footer>
        </form>
      </div>
    );
  }
}

WhatsAppForm.defaultProps = {
  titleForm: 'Fale pelo WhatsApp',
  handleSubmit() {},
  handleCloseOverlay() {},
  isSubmittingForm: false,
  shouldShowDataPermissions: false,
  showEmail: true,
  showContactOptions: true,
  units: [],
  filterUnitsByLocation: false,
  product: '',
  channel: '',
  category: '',
  brand: '-',
  bait: '-6',
  whatsAppResponder: false,
  showUnits: true,
  showCpf: false,
  versions: [],
  customFields: [],
  showLocationFields: false,
  showOverlay: null,
  errorSendingForm: null,
  country: '',
  cloneId: null,
  dataPermissionsCustomText: '',
  formId: '',
  unit: '',
  linkPrivacyPolicy: '',
  modelCategory: '',
};

WhatsAppForm.propTypes = {
  titleForm: PropTypes.string,
  handleSubmit: PropTypes.func,
  handleCloseOverlay: PropTypes.func,
  showOverlay: PropTypes.bool,
  errorSendingForm: PropTypes.bool,
  isSubmittingForm: PropTypes.bool,
  units: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    }),
  ),
  filterUnitsByLocation: PropTypes.bool,
  product: PropTypes.string,
  channel: PropTypes.string,
  category: PropTypes.string,
  brand: PropTypes.string,
  country: PropTypes.string,
  bait: PropTypes.string,
  whatsAppResponder: PropTypes.bool,
  showUnits: PropTypes.bool,
  showCpf: PropTypes.bool,
  showEmail: PropTypes.bool,
  showContactOptions: PropTypes.bool,
  showLocationFields: PropTypes.bool,
  cloneId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  versions: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.number,
    }),
  ),
  customFields: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      value: PropTypes.string,
    }),
  ),
  shouldShowDataPermissions: PropTypes.bool,
  dataPermissionsCustomText: PropTypes.string,
  formId: PropTypes.string,
  unit: PropTypes.string,
  linkPrivacyPolicy: PropTypes.string,
  modelCategory: PropTypes.string,
};

export default WhatsAppForm;
