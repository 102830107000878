import DDMOffers from '../auto/stores/DDMOffers';
import DDMOffersFilter from '../auto/stores/DDMOffersFilter';
import VersionComparison from '../auto/stores/VersionComparison';
import FilterV2 from '../auto/stores/Filters/UsedVehicles/FilterV2';
import FilterV2Options from '../auto/stores/Filters/UsedVehicles/FilterV2Options';
import SearchFilter from '../auto/stores/SearchFilter';

(function () {
  // Adicione chaves:valor com os stores que serão atribuídos ao escopo
  // global
  const store = {
    DDMOffers,
    DDMOffersFilter,
    VersionComparison,
    FilterV2,
    FilterV2Options,
    SearchFilter,
  };
  window.store = Object.assign(window.store || {}, {});
  Object.keys(store).forEach(c => {
    window.store[c] = store[c]();
  });
})();
