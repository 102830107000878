import { h, Component } from 'preact';
import PropTypes from 'prop-types';
import CustomSelect from './CustomSelect';
import CustomCheck from './CustomCheck';
import FormMessageOverlay from './FormMessageOverlay';
import SubmitButton from './SubmitButton';
import validationFactory from '%/utils/validation';
import IMask from 'imask';
import { phoneMasks, phoneMasksPt } from '%/utils/masks';
import getUTM from '%/utils/utm';
import handleInputChange from '%/utils/handleInputChange';
import InputCpf from './InputCpf';
import LocationFields from './Inputs/Location';
import ZFlow from './ZFlow';
import classNames from 'classnames';

/**
 * @property {VariantCollection} variantsCollection
 */
export default class UsedVehicleConversionForm extends Component {
  constructor(props) {
    super(props);

    this.resetState();

    this.getFormData = this.getFormData.bind(this);
    this.handleInputChange = handleInputChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleContactOptionsChange =
      this.handleContactOptionsChange.bind(this);
    this.handleFandiButton = this.handleFandiButton.bind(this);
    this.handleStateChange = this.handleStateChange.bind(this);
    this.handleCityChange = this.handleCityChange.bind(this);
  }

  resetState() {
    this.setState({
      name: null,
      email: null,
      phone: null,
      message: null,
      phoning: false,
      mailing: false,
      whatsapping: false,
      contactOptions: '',
      cpf: '',
      dataPermissions: false,
      uf: null,
      city: null,
    });
  }

  /**
   * Validação extra para o cpf
   */
  cpfValid() {
    return !this.props.showCpf || (this.props.showCpf && this.state.cpf);
  }

  validateLocationFields() {
    if (!this.state.city) {
      document
        .getElementsByName('city')[0]
        .parentElement.classList.add('is-invalid');
      if (!this.state.uf) {
        document
          .getElementsByName('uf')[0]
          .parentElement.classList.add('is-invalid');
      } else {
        document
          .getElementsByName('uf')[0]
          .parentElement.classList.remove('is-invalid');
      }
    }
  }

  handleStateChange(e) {
    const uf = e.target.value;
    this.setState({ uf });
  }

  handleCityChange(e) {
    const city = e.target.value;
    this.setState({ city });
  }

  handleSubmit(e) {
    e.preventDefault();

    const invalidInputs = this.validator.validateAll(this.form);
    const formIsValid = invalidInputs.length === 0;

    if (this.props.showLocationFields) this.validateLocationFields();

    if (!formIsValid) {
      return;
    }

    if (!this.cpfValid()) {
      alert('O campo de CPF é obrigatório');
      return;
    }

    // Se o form for válido, chamamos a prop para gerenciar a submissão do form.
    this.props.handleSubmit(e, this.getFormData()).then(() => {
      this.resetState();
    });
  }

  handleContactOptionsChange(e) {
    this.handleInputChange(e);
    this.state.contactOptions = this.contactOptionsValue() ? 'on' : '';
  }

  handleFandiButton(e) {
    e.preventDefault();

    window.dispatchEvent(
      new CustomEvent('OpenFandi', { detail: this.getFormData() }),
    );
  }

  /*
   * Verifica se não há opções de contato selecionadas
   */
  contactOptionsValue() {
    return !!(
      this.state.phoning ||
      this.state.mailing ||
      this.state.whatsapping
    );
  }

  componentDidMount() {
    const masksByCountry = {
      BR: phoneMasks,
      PT: phoneMasksPt,
    };
    const country = this.props.country;
    const phoneMask = IMask(this.phoneInput, {
      mask: masksByCountry[country],
    });
    phoneMask.on('accept', () => this.setState({ phone: phoneMask.value }));

    this.validator = validationFactory(`#${this.props.formId}`);
  }

  /**
   * Retorna os dados pertinentes para a conversão.
   * @return {*}
   */
  getFormData() {
    let state = {
      name: this.state.name,
      email: this.state.email,
      phone: this.state.phone,
      message: this.state.message,
      mailing: this.state.mailing,
      phoning: this.state.phoning,
      whatsapping: this.state.whatsapping,
    };

    if (this.props.showCpf) {
      state.cpf = this.state.cpf;
    }

    if (this.props.shouldShowDataPermissions) {
      state.data_permissions = this.state.dataPermissions;
    }

    if (this.props.showLocationFields) {
      state.uf = this.state.uf;
      state.city = this.state.city;
    }

    let props = {
      product: this.props.product,
      bait: -5,
      channel: this.props.channel,
      category: this.props.category,
      brand: this.props.brand,
      utmz: getUTM(),
    };

    if (this.props.unit) {
      props['unit'] = this.props.unit;
    } else if (this.props.units.length === 1) {
      props['unit'] = this.props.units[0].value;
    } else {
      props['unit'] = this.state.unit;
    }

    return { ...state, ...props };
  }

  render() {
    const { showFandiButton } = this.props;

    return (
      <form
        className="conversion-form"
        ref={form => (this.form = form)}
        id={this.props.formId}
        onSubmit={this.handleSubmit}
        novalidate
      >
        <FormMessageOverlay
          handleClose={this.props.handleCloseOverlay}
          isVisible={this.props.showOverlay}
          type={this.props.errorSendingForm ? 'error' : 'success'}
        />
        <header>
          <div className="conversion-form__header-phrase">
            <h2>{this.props.titleForm}</h2>
            <p>{this.props.mainPhrase}</p>
          </div>
        </header>
        <div className="form-conversion__body">
          <fieldset>
            <div className="form-group">
              {this.props.showLabels && (
                <label className="conversion-form__control-label">Nome</label>
              )}
              <input
                onChange={this.handleInputChange}
                value={this.state.name}
                type="text"
                maxLength={80}
                className="form-control"
                name="name"
                required
                placeholder="Nome"
                data-bouncer-target="#invalid-name"
              />
              <div id="invalid-name" className="invalid-feedback" />
            </div>
            <div className="form-group">
              {this.props.showLabels && (
                <label className="conversion-form__control-label">E-mail</label>
              )}
              <input
                onChange={this.handleInputChange}
                value={this.state.email}
                type="email"
                maxLength={80}
                required={
                  this.props.contactsWithDynamicRequired
                    ? this.state.mailing
                    : true
                }
                className="form-control"
                name="email"
                placeholder="E-mail"
                data-bouncer-target="#invalid-email"
              />
              <div id="invalid-email" className="invalid-feedback" />
            </div>
            <div className="form-group">
              {this.props.showLabels && (
                <label className="conversion-form__control-label">
                  Telefone
                </label>
              )}
              <input
                onChange={this.handleInputChange}
                value={this.state.phone}
                ref={phoneInput => (this.phoneInput = phoneInput)}
                className="form-control"
                name="phone"
                required={
                  this.props.contactsWithDynamicRequired
                    ? this.state.phoning || this.state.whatsapping
                    : true
                }
                type="phone"
                autoComplete="off"
                data-bouncer-target="#invalid-phone"
                placeholder="Telefone/Whatsapp"
                data-should-validate={
                  this.props.contactsWithDynamicRequired
                    ? String(this.state.phoning || this.state.whatsapping)
                    : 'true'
                }
              />
              <div id="invalid-phone" className="invalid-feedback" />
            </div>

            {this.props.showCpf && (
              <InputCpf
                handleChange={this.handleInputChange}
                value={this.state.cpf}
                label={this.props.showLabels && 'CPF'}
              />
            )}

            {this.props.showLocationFields && (
              <LocationFields
                handleStateChange={this.handleStateChange}
                uf={this.state.uf}
                handleCityChange={this.handleCityChange}
                city={this.state.city}
              />
            )}

            {!this.props.unit &&
              this.props.units.length > 1 &&
              this.props.showUnits && (
                <div className="form-group">
                  <label className="conversion-form__control-label">
                    Escolha a unidade:
                  </label>
                  <CustomSelect
                    handleSelectChange={this.handleInputChange}
                    name="unit"
                    options={this.props.units}
                    value={this.state.unit}
                    shouldSort={false}
                    placeholderValue="Escolha a unidade"
                    searchEnabled={false}
                  />
                </div>
              )}

            {this.props.showMessageField && (
              <div className="form-group">
                {this.props.showLabels && (
                  <label className="conversion-form__control-label">
                    Mensagem
                  </label>
                )}
                <textarea
                  className="form-control"
                  maxlength="255"
                  placeholder="Mensagem"
                  name="message"
                  value={this.state.message}
                  onChange={this.handleInputChange}
                ></textarea>
              </div>
            )}
          </fieldset>
          {this.props.zflow && (
            <ZFlow
              isSubmitting={this.props.isSubmittingForm}
              getFormData={this.getFormData}
            />
          )}
          <fieldset>
            <legend>Quero receber contato por:</legend>
            <div className="form-check form-check-inline">
              <CustomCheck
                name="mailing"
                value="true"
                onChangeCheckable={this.handleContactOptionsChange}
                isChecked={this.state.mailing}
                type="checkbox"
              >
                E-mail
              </CustomCheck>
            </div>
            <div className="form-check form-check-inline">
              <CustomCheck
                name="whatsapping"
                value="true"
                isChecked={this.state.whatsapping}
                onChangeCheckable={this.handleContactOptionsChange}
                type="checkbox"
              >
                Whatsapp
              </CustomCheck>
            </div>
            <div className="form-check form-check-inline">
              <CustomCheck
                name="phoning"
                value="true"
                isChecked={this.state.phoning}
                onChangeCheckable={this.handleContactOptionsChange}
                type="checkbox"
              >
                Telefone
              </CustomCheck>
            </div>
            <div className="form-group">
              <input
                type="text"
                className="form-control d-none"
                name="contact-options"
                required
                placeholder="Opções de contato"
                data-bouncer-target="#invalid-contact-options"
                value={this.state.contactOptions}
                checked={this.state.contactOptions}
              />
              <div id="invalid-contact-options" className="invalid-feedback" />
            </div>
          </fieldset>
          {this.props.shouldShowDataPermissions && (
            <fieldset className="mt-0 mb-2">
              <div className="form-check form-check-inline conversion-form__data-permissions-field">
                <CustomCheck
                  name="dataPermissions"
                  value="true"
                  isChecked={this.state.dataPermissions}
                  onChangeCheckable={this.handleInputChange}
                  type="checkbox"
                  checkStyle={this.props.checkStyle}
                >
                  {this.props.dataPermissionsCustomText}
                </CustomCheck>
              </div>
            </fieldset>
          )}
          <footer className="form-conversion__footer">
            Ao informar meus dados, eu concordo com a{' '}
            <a href={this.props.linkPrivacyPolicy}>Política de privacidade</a>.
          </footer>
          <hr className="my-3"></hr>
          <SubmitButton
            classes={classNames(
              'btn button button--large button--primary w-100',
              {
                'mb-3': !!showFandiButton,
                'mb-0': !showFandiButton,
              },
            )}
            label="ESTOU INTERESSADO"
            isSubmitting={this.props.isSubmittingForm}
            handleClick={this.handleSubmit}
          />
          {showFandiButton && (
            <a
              href="#"
              className="btn button button--large button--fandi w-100 mb-0"
              onClick={this.handleFandiButton}
            >
              Simular Financiamento
            </a>
          )}
        </div>
      </form>
    );
  }
}

// default props
UsedVehicleConversionForm.defaultProps = {
  titleForm: 'Estou Interessado',
  handleSubmit: function () {},
  handleCloseOverlay: function () {},
  handleVariantChange: function () {},
  isSubmittingForm: false,
  errorSendingForm: false,
  showOverlay: false,
  shouldShowPrice: true,
  shouldShowVariantSelect: true,
  // defaultVariant representa o valor de variante selecionado
  // Esse valor terá precedência sobre o valor selecionado pelo CustomSelect,
  // portanto, cuidado.
  defaultVariant: '',
  units: [],
  brand: '-',
  showUnits: true,
  showCpf: false,
  zflow: false,
  contactsWithDynamicRequired: false,
  shouldShowDataPermissions: false,
  showFandiButton: false,
  showLocationFields: false,
  showLabels: false,
  showMessageField: true,
};

UsedVehicleConversionForm.propTypes = {
  formId: PropTypes.string.isRequired,
  titleForm: PropTypes.string,
  isSubmittingForm: PropTypes.bool,
  errorSendingForm: PropTypes.bool,
  showOverlay: PropTypes.bool,
  handleSubmit: PropTypes.func.isRequired,
  handleCloseOverlay: PropTypes.func,
  mainPhrase: PropTypes.string,
  linkPrivacyPolicy: PropTypes.string,
  units: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.number,
    }),
  ),
  unit: PropTypes.string,
  product: PropTypes.string,
  channel: PropTypes.string,
  category: PropTypes.string,
  brand: PropTypes.string,
  country: PropTypes.string,
  showUnits: PropTypes.bool,
  showCpf: PropTypes.bool,
  zflow: PropTypes.bool,
  contactsWithDynamicRequired: PropTypes.bool,
  shouldShowDataPermissions: PropTypes.bool,
  dataPermissionsCustomText: PropTypes.string,
  showFandiButton: PropTypes.bool,
  showLocationFields: PropTypes.bool,
  showLabels: PropTypes.bool,
  showMessageField: PropTypes.bool,
};
