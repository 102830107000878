// eslint-disable-next-line import/no-unresolved
import { Component } from 'preact';
import PropTypes from 'prop-types';
import Item from './Item';
import ItemPlaceholder from './ItemPlaceholder';

class List extends Component {
  constructor(props) {
    super(props);

    this.placeholderCards = Array.from({ length: 12 }).map((_, index) => (
      // eslint-disable-next-line react/no-array-index-key
      <div className="col-md-6 col-lg-3 mb-4" key={index}>
        <ItemPlaceholder long />
      </div>
    ));
  }

  componentDidMount() {
    window.matchHeight.match('.list-used-models');
  }

  componentDidUpdate() {
    window.matchHeight.match('.list-used-models');
  }

  render() {
    const { items, loading, loadingMore, used_models_link } = this.props;

    return (
      <div className="list-used-models__list">
        <div className="row">
          {loading && !loadingMore && this.placeholderCards}

          {items.map((item, index) => (
            <div className="col-md-6 col-lg-3 mb-4">
              {loading && !loadingMore ? (
                <ItemPlaceholder />
              ) : (
                <Item
                  item_image={item.item_image}
                  model={item.model}
                  year={item.fabrication_year}
                  slug={item.slug}
                  kind={item.kind}
                  badge={item.badge}
                  subtitle={item.subtitle}
                  brand={item.brand}
                  price={item.price}
                  old_price={item.old_price}
                  used_models_link={used_models_link}
                  seals={item.seals}
                  km={item.km}
                  fuel_text={item.fuel_text}
                  exchange={item.exchange}
                  isOnFirstPage={index < 4}
                />
              )}
            </div>
          ))}
        </div>
      </div>
    );
  }
}

List.defaultProps = {
  items: [],
  loadingMore: false,
};

List.propTypes = {
  items: PropTypes.arrayOf({}),
  loading: PropTypes.bool,
  loadingMore: PropTypes.bool,
  used_models_link: PropTypes.string,
};

export default List;
